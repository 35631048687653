<template>
  <div id="main">
    <div id="top">
      <img id="logo" :src='require("@/assets/img/index/logo.png")' @click='checkMenu("/home")' />
      <ul id="menu">
        <li :class='activeMenu=="/home"?"home active":"home"' @click='checkMenu("/home")'>
          <span class="text">首页</span>
          <span class="bottomLine"></span>
        </li>
        <li :class='activeMenu.indexOf("/product")==0?"product active":"product"' @click='checkMenu("/product/t20")'
          @mouseenter='enterMenu("product")' @mouseleave='leaveMenu("product")'>
          <span class="text">产品</span>
          <span class="bottomLine"></span>
        </li>
        <li :class='activeMenu=="/app"?"app active":"app"' @click='checkMenu("/app")'>
          <span class="text">APP</span>
          <span class="bottomLine"></span>
        </li>
        <li :class='activeMenu=="/technology"?"technology active":"technology"' @click='checkMenu("/technology")'>
          <span class="text">核心技术</span>
          <span class="bottomLine"></span>
        </li>
        <li :class='activeMenu.indexOf("/about")==0?"about active":"about"' @click='checkMenu("/about/info")'
          @mouseenter='enterMenu("about")' @mouseleave='leaveMenu("about")'>
          <span class="text">关于我们</span>
          <span class="bottomLine"></span>
        </li>
        <li :class='activeMenu.indexOf("/empower")==0?"empower active":"empower"' @click='checkMenu("/empower")'>
          <span class="text">授权查询</span>
          <span class="bottomLine"></span>
        </li>
      </ul>
      <span id="hotLine">
                <span class="text">服务热线：400-133-5668</span>
                <span class="img" @click='openUrl("https://lanting.tmall.com")'></span>
            </span>
    </div>
    <div id="topHover" :style='{display:hover.isShow?"block":"none", top:(hover.top/100)+"rem"}'>
      <div id="product-detail" v-if='hover.type=="product"' @mouseenter='enterMenuDetail("product")'
        @mouseleave="leaveMenuDetail()">
        <span class="class1">盆底康复治疗</span>
        <p class="product-detail-item wrightin-t20" @click.stop='checkMenu("/product/t20")'>
          <img :src="require('@/assets/img/index/product-t20.png')" />
          <span>澜渟T20</span>
          <img class="new-icon" :src="require('@/assets/img/index/new-icon.png')" />
        </p>
        <p class="product-detail-item wrightin-luolan" @click.stop='checkMenu("/product/luolan")'>
          <img :src="require('@/assets/img/index/product-luolan.png')" />
          <span>洛澜</span>
        </p>
        <p class="product-detail-item wrightin-mulan" @click.stop='checkMenu("/product/mulan")'>
          <img :src="require('@/assets/img/index/product-mulan.png')" />
          <span>沐澜</span>
        </p>
        <p class="product-detail-item wrightin-two" @click.stop='checkMenu("/product/wrightinTwo")'>
          <img :src="require('@/assets/img/index/product-wrightinTwo.png')" />
          <span>T20 Max</span>
        </p>
        <p class="product-detail-item wrightin" @click.stop='checkMenu("/product/wrightin")'>
          <img :src="require('@/assets/img/index/product-wrightin.png')" />
          <span>初澜</span>
        </p>

        <span class="class2">盆底训练辅助</span>
        <p class="product-detail-item kegel" @click.stop='checkMenu("/product/kegel")'>
          <img :src="require('@/assets/img/index/product-kegel.png')" />
          <span>凯格尔球</span>
        </p>
        <span class="class3">产后形体修护</span>
        <p class="product-detail-item wireless" @click.stop='checkMenu("/product/wireless")'>
          <img :src="require('@/assets/img/index/product-wireless.png')" />
          <span>无线产后恢复仪</span>
        </p>
      </div>
      <div id="about-detail" v-if='hover.type=="about"' @mouseenter="enterMenuDetail()" @mouseleave="leaveMenuDetail()">
        <p class="about-detail-item info" @click.stop='checkMenu("/about/info")'>
          <span>公司简介</span>
        </p>
        <p class="about-detail-item consultation" @click.stop='checkMenu("/about/consultation")'>
          <span>品牌资讯</span>
        </p>
        <p class="about-detail-item contact" @click.stop='checkMenu("/about/contact")'>
          <span>联系我们</span>
        </p>
        <p class="about-detail-item join" @click.stop='checkMenu("/about/join")'>
          <span>加入我们</span>
        </p>
        <p class="about-detail-item responsibility" @click.stop='checkMenu("/about/responsibility")'>
          <span>社会责任</span>
        </p>
      </div>
    </div>
    <div id="content">
      <router-view />
    </div>
    <div id="bottom">
      <div class="info product">
        <p class="title">产品&技术</p>
        <span class="item" style="margin-left: 0; width: 0.52rem" @click='checkMenu("/product/t20")'>澜渟T20</span>
        <span class="item" style="margin-left: 0.6rem; width: 0.26rem" @click='checkMenu("/product/luolan")'>洛澜</span>
        <span class="item" style="margin-left: 0.86rem; width: 0.26rem" @click='checkMenu("/product/mulan")'>沐澜</span>
        <span class="item" style="margin-left: 0.86rem; width: 0.66rem"
          @click='checkMenu("/product/wrightinTwo")'>T20 Max</span>
        <span class="item" style="margin-left: 0.46rem; width: 0.26rem"
          @click='checkMenu("/product/wrightin")'>初澜</span>
        <span class="item" style="margin-left: 0; width: 0.52rem" @click='checkMenu("/product/kegel")'>凯格尔球</span>
        <span class="item" style="margin-left: 0.6rem; width: 0.91rem" @click='checkMenu("/product/wireless")'>无线产后恢复仪</span>
        <span class="item" style="margin-left: 0.21rem; width: 0.51rem" @click='checkMenu("/app")'>澜渟App</span>
        <span class="item" style="margin-left: 0.61rem; width: 0.77rem" @click='checkMenu("/app")'>澜渟医生App</span>
        <span class="item" style="margin-left: 0.35rem; width: 0.52rem" @click='checkMenu("/technology")'>核心技术</span>
      </div>
      <div class="info about">
        <p class="title">关于我们</p>
        <span class="item" style="margin-left: 0; width: 0.52rem" @click='checkMenu("/about/info")'>公司简介</span>
        <span class="item" style="margin-left: 0.6rem; width: 0.52rem"
          @click='checkMenu("/about/consultation")'>品牌资讯</span>
        <span class="item" style="margin-left: 0.6rem; width: 0.52rem" @click='checkMenu("/about/contact")'>联系我们</span>
        <span class="item" style="margin-left: 0.6rem; width: 0.52rem" @click='checkMenu("/about/join")'>加入我们</span>
      </div>
      <div class="info link">
        <p class="title">友情链接</p>
        <span class="item" style="margin-left: 0; width: 0.39rem"
          @click='openUrl("http://www.medlander.com")'>麦澜德</span>
        <span class="item" style="margin-left: 0.73rem; width: 0.52rem" @click='openUrl("http://www.jialan365.com/")'>佳澜健康</span>
        <span class="item" style="margin-left: 0.6rem; width: 0.39rem"
          @click='openUrl("https://www.reseader.com")'>锐诗得</span>
      </div>
      <div class="qrcode wechat">
        扫描二维码<br />关注澜渟微信公众号
      </div>
      <div class="qrcode app">
        扫描二维码<br />下载澜渟App
      </div>
      <div class="qrcode app-doctor">
        扫描二维码<br />下载澜渟医生App
      </div>
      <div class="phone">
        <div class="left">
          <p>服务热线：周一至周日 9:00-18:00</p>
          <p>400-133-5668</p>
        </div>
        <div class="right">
          <p>经销合作：</p>
          <p>17372217350</p>
        </div>
      </div>
      <div class="copyright">
        <p>互联网药品信息服务资格证书：(苏)-非经营性-2023-0070</p>
        <p @click='openUrl("https://beian.miit.gov.cn/#/Integrated/index")'>Copyright © 2015-{{ copyRightYear }}
          南京麦豆健康科技有限公司 版权所有 苏ICP备 17044321 号</p>
        <p>投诉举报电话: 12331</p>
      </div>
    </div>
    <div id="goTop" v-if="goTopShow" @click="goTop()">
      <img class="img" v-lazy='require("@/assets/img/index/goTop.png")'>
      <img class="img-hover" v-lazy='require("@/assets/img/index/goTop-hover.png")'>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: '/home',
      hover: {
        isShow: false,
        type: 'product',
        top: -158,
      },
      scrollTop: 0,
      goTopShow: false,
      copyRightYear: new Date().getFullYear(),
    }
  },
  mounted() {
    window.addEventListener('scroll', this.bindScrollWindow, true)
  },
  methods: {
    /**
     监听页面滚动条
     @param
     @return
     */
    bindScrollWindow() {
      this.scrollTop = window.scrollY
      this.goTopShow = window.scrollY > window.screen.availHeight * .06
    },
    /**
     选中菜单
     @param {string} menu 菜单
     @return
     */
    checkMenu(menu) {
      if (this.activeMenu == menu) {
        this.goTop()
      } else {
        this.jump(menu)
      }
      this.leaveMenuDetail()
    },
    /**
     页面跳转
     @param {string} path 跳转路径
     @return
     */
    jump(path) {
      this.$router.push({path: path})
    },
    /**
     打开链接
     @param {string} url 链接
     @return
     */
    openUrl(url) {
      window.open(url)
    },
    /**
     置顶
     @param
     @return
     */
    goTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
    /**
     鼠标移入菜单
     @param {string} menu 菜单
     @return
     */
    enterMenu(menu) {
      this.hover.isShow = true
      this.hover.type = menu
      if (this.hover.top >= 72) {
        return
      }
      this.interval = setInterval(() => {
        if (this.hover.top >= 72) {
          clearInterval(this.interval)
          return
        }
        this.hover.top += 23
      }, 20)
    },
    /**
     鼠标移出菜单
     @param
     @return
     */
    leaveMenu() {
      clearInterval(this.interval)
      this.hover.isShow = false
      this.hover.top = -158
    },
    /**
     鼠标移入菜单详情
     @param
     @return
     */
    enterMenuDetail() {
      this.hover.isShow = true
      this.hover.top = 72
    },
    /**
     鼠标移出菜单详情
     @param
     @return
     */
    leaveMenuDetail() {
      this.hover.isShow = false
      this.hover.top = -158
    },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.activeMenu = newVal.path
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
#main {
  height: 100%;
}

#top {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: .72rem;
  background-color: #fff;

  > #logo {
    position: absolute;
    top: .18rem;
    left: 3.6rem;
    height: .36rem;
    cursor: pointer;
  }

  > #menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 6.6rem;
    height: .72rem;
    white-space: nowrap;
    background-color: #fff;

    > li {
      position: relative;
      display: inline-block;
      padding-top: .25rem;
      padding-left: .33rem;
      padding-right: .33rem;
      height: .72rem;
      cursor: pointer;

      > .text {
        height: .22rem;
        line-height: .22rem;
      }

      > .bottomLine {
        display: none;
        position: absolute;
        bottom: .1rem;
        left: 0;
        right: 0;
        margin: auto;
        width: .2rem;
        height: 0.02rem;
        background-color: $md-color-primary;
      }
    }

    > li:nth-child(1) {
      margin-left: 0;
    }

    > li.active {
      > .text {
        color: $md-color-primary;
      }

      > .bottomLine {
        display: block;
      }
    }

    > li:hover {
      > .text {
        color: $md-color-primary;
      }
    }
  }

  > #hotLine {
    position: absolute;
    top: .27rem;
    right: 3.6rem;
    height: .2rem;
    background-color: #fff;

    > .text {
      float: left;
      height: .2rem;
      line-height: .2rem;
      white-space: nowrap;
      font-size: .14rem;
      color: #666;
    }

    > .img {
      float: left;
      margin-left: .26rem;
      width: .16rem;
      height: .17rem;
      background: url(./assets/img/index/hotline.png) no-repeat center center;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
}

#topHover {
  display: none;
  position: fixed;
  z-index: 97;
  top: -1.58rem;
  left: 0;
  right: 0;
  height: 2.3rem;
  background-color: #fafafa;

  > #product-detail {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    height: 2.92rem;
    background: #ffffff;
    box-shadow: 0rem 0.03rem 0.12rem 0rem rgba(231, 231, 231, 0.5);

    > span {
      position: absolute;
      top: 0.38rem;
      color: #333;
      font-size: 0.16rem;
      line-height: 1;
    }

    > .product-detail-item {
      position: absolute;
      top: 0.69rem;
      width: 1.2rem;
      height: 1.52rem;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: url(./assets/img/index/product.png) no-repeat top;
      background-size: 1.2rem 1.2rem;

      > img {
        width: 1.2rem;
        height: 1.2rem;
      }

      > span {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        line-height: 1;
        font-size: .16rem;
        color: #333;
      }
    }

    > .class1 {
      left: 4.02rem;
    }

    > .class2 {
      left: 11.62rem;
    }

    > .class3 {
      left: 14.04rem;
    }

    > .product-detail-item.wrightin-t20 {
      left: 3.98rem;

      >.new-icon {
        width: 0.46rem;
        height: 0.16rem;
        position: absolute;
      }
    }

    > .product-detail-item.wrightin-luolan {
      left: 5.29rem;
    }

    > .product-detail-item.wrightin-mulan {
      left: 6.59rem;
    }

    > .product-detail-item.wrightin-two {
      left: 7.89rem;
    }

    > .product-detail-item.wrightin {
      left: 9.18rem;
    }

    > .product-detail-item.kegel {
      right: 6.36rem;
    }

    > .product-detail-item.wireless {
      right: 3.95rem;
    }

    > .product-detail-item:hover {
      background: url(./assets/img/index/product_hover.png) no-repeat top;
      background-size: 1.2rem 1.2rem;

      > span {
        color: $md-color-primary;
      }
    }
  }

  > #about-detail {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fafafa;
    box-shadow: 0rem 0.03rem 0.12rem 0rem rgba(231, 231, 231, 0.5);
    padding: 0.38rem 3.6rem 0.44rem;
    display: flex;
    justify-content: space-between;

    > .about-detail-item {
      width: 1.48rem;
      height: 1.48rem;
      cursor: pointer;
      background-size: 1.48rem 1.1rem !important;
      display: flex;
      flex-direction: column-reverse;

      > span {
        text-align: center;
        line-height: .21rem;
        font-size: .15rem;
        color: #444;
      }
    }

    > .about-detail-item.info {
      background: url(./assets/img/index/about-info.png) no-repeat center top;
    }

    > .about-detail-item.consultation {
      background: url(./assets/img/index/about-consultation.png) no-repeat center top;
    }

    > .about-detail-item.contact {
      background: url(./assets/img/index/about-contact.png) no-repeat center top;
    }

    > .about-detail-item.join {
      background: url(./assets/img/index/about-join.png) no-repeat center top;
    }

    > .about-detail-item.responsibility {
      background: url(./assets/img/index/about-responsibility.png) no-repeat center top;
    }

    > .about-detail-item:hover {
      > span {
        color: $md-color-primary;
      }
    }
  }
}

#content {
  clear: both;
  position: relative;
  padding-top: .72rem;
  min-height: 100%;
}

#bottom {
  position: relative;
  height: 4.33rem;
  background-color: #30293D;

  > .info {
    width: 5.1rem;
    position: absolute;
    left: 3.6rem;

    > .title {
      line-height: .2rem;
      font-size: .14rem;
      letter-spacing: .0108rem;
      color: #fff;
      width: 5.1rem;
      padding-bottom: 0.05rem;
      border-bottom: 0.01rem solid #666666;
    }

    > .item {
      margin-top: .1rem;
      height: .17rem;
      line-height: .17rem;
      font-size: .12rem;
      letter-spacing: .0093rem;
      color: #999;
      cursor: pointer;
      display: inline-block;
      word-break: keep-all;
    }

    > .item:nth-child(2) {
    }

    > .item:hover {
      color: #fff;
    }
  }

  > .info.product {
    top: 0.3rem;
  }

  > .info.about {
    top: 1.34rem;
  }

  > .info.link {
    top: 2.11rem;
  }

  > .qrcode {
    position: absolute;
    top: .42rem;
    left: 0;
    right: 0;
    padding-top: 1.07rem;
    width: 1.17rem;
    height: 1.42rem;
    text-align: center;
    line-height: .17rem;
    font-size: .12rem;
    letter-spacing: .0093rem;
    color: #999;
    word-break: keep-all;
  }

  > .qrcode.wechat {
    left: 11.09rem;
    background: url(./assets/img/index/qrcode-wechat.png) no-repeat center top;
    background-size: 1.0rem 1.0rem;
  }

  > .qrcode.app {
    left: 12.87rem;
    background: url(./assets/img/index/qrcode-app.png) no-repeat center top;
    background-size: 1.0rem 1.0rem;
  }

  > .qrcode.app-doctor {
    left: 14.57rem;
    background: url(./assets/img/index/qrcode-app-doctor.png) no-repeat center top;
    background-size: 1.0rem 1.0rem;
  }

  > .phone {
    position: absolute;
    top: 2.14rem;
    left: 11.1rem;

    > .left {
      > p {
        color: #ffffff;
        font-size: 0.14rem;
        line-height: 0.2rem;
        letter-spacing: 1px;
      }

      > p:nth-child(2) {
        font-size: 0.24rem;
        line-height: 0.33rem;
        letter-spacing: 0.0265rem;
      }
    }

    > .right {
      position: absolute;
      top: 0;
      left: 2.65rem;
      padding-left: 0.25rem;
      border-left: 0.01rem solid #666666;

      > p {
        color: #ffffff;
        font-size: 0.14rem;
        line-height: 0.2rem;
        letter-spacing: 1px;
      }

      > p:nth-child(2) {
        font-size: 0.24rem;
        line-height: 0.33rem;
        letter-spacing: 0.0265rem;
      }
    }
  }

  > .copyright {
    position: absolute;
    bottom: .25rem;
    left: 0;
    right: 0;

    > p {
      height: .17rem;
      text-align: center;
      line-height: .17rem;
      font-size: .12rem;
      color: #666;
    }

    > p:nth-child(2) {
      margin-top: .04rem;
      cursor: pointer;
    }

    > p:nth-child(3) {
      margin-top: .22rem;
    }
  }
}

#goTop {
  position: fixed;
  z-index: 97;
  bottom: 1.0rem;
  right: .2rem;
  width: .4rem;
  height: .4rem;
  cursor: pointer;

  > img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: .4rem;
    height: .4rem;
  }

  > .img {
    z-index: 2;
  }

  > .img-hover {
    z-index: 1;
  }
}

#goTop:hover {
  > .img {
    z-index: 1;
  }

  > .img-hover {
    z-index: 2;
  }
}
</style>
<style lang="scss">
@import './assets/css/common.scss';
</style>
